import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Community, CompanyConfigMap, Organization, Person, Prospect, Referrer, ReferrerStaff } from '../../models/user.models';
import { faEnvelope, faMessage, faPhone, faStar } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersonService } from '../../services/person.service';
import { ContactService } from '../../services/communication.service';
import { Email, Phone } from '../../models/base.models';
import { MatDialog } from '@angular/material/dialog';
import { EmailSendComponent } from '../../dialog/email-send/email-send.component';
import { ChatDialogComponent } from '../../dialog/chat-dialog/chat-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '../../services/app.service';
import { SidebarWrapperComponent } from '../../../view/sidebar-wrapper.view';
import { CompanyCfgService } from '../../services/company-cfg.service';


@Component({
  selector: 'person-contact',
  templateUrl: './person-contact.component.html',
  styleUrls: ['./person-contact.component.scss']
})
@UntilDestroy()
export class PersonContactComponent implements OnChanges {
    @Input() person!:Person;
    @Input() other_people!:any[]|null;
    @Input() getProspectData!: any;
    @Input() getReferrerData!: any;
    @Input() getReferrerStaffData!: any;
    @Input() size:"small"|"mid"|"large" = "small";
    @Input() contained?:any|undefined;

    @ViewChild(SidebarWrapperComponent, { read: ElementRef })
      private ccpDiv!: HTMLElement;


    starIcon = faStar;
    emailIcon = faEnvelope;
    messageIcon = faMessage;
    phoneIcon = faPhone;

    phones:{person:any, phones:Phone[], header?:string}[] = [];
    mobiles:{person:any, phones:Phone[], header?:string}[] = [];
    emails:{person:any, emails:Email[], header?:string}[] = [];
    currentCommunity: Community | null | undefined;
    referrerCRM = this.companyCfg.referrerCRM;
    usesPhoneApi = this.companyCfg.usesPhoneApi;
    
    constructor(
      private snackbar:MatSnackBar, 
      public personService:PersonService, 
      private communication:ContactService, 
      private appService:AppService,
      private dialog: MatDialog, 
      private companyCfg:CompanyCfgService){
        
        personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(community=>{
          this.currentCommunity = community;
        });
    }

    public ngAfterViewInit(): void {
      this.ccpDiv = this.appService.ccpDiv;
    }
    
    

    isPerson(contact:any){
      return contact.person instanceof Person;
    }

    isOrganization(contact:any){
      return contact.person instanceof Organization;
    }
    
    comingSoon(){
      this.snackbar.open("Coming Soon");
    }

    selectCall(person:Person, phone:Phone){
        if(phone && phone.number != ""){
        
            const ccpUrl = this.currentCommunity?.acUrl;
            const kcUrl = this.currentCommunity?.kcUrl;
            const instanceId = this.currentCommunity?.acInstanceId; 
          
          /*connect.core.initCCP(this.ccpDiv, {
            ccpAckTimeout: 5 * 1000, // use 5s instead of the default 3s
            ccpUrl: ccpUrl || "", 
            region: 'us-east-1',
            loginPopup: true,
            loginPopupAutoClose: true,
            loginUrl: kcUrl + '?RelayState=https://us-east-1.console.aws.amazon.com/connect/federate/'+instanceId+'?destination=/connect/ccp',
            softphone: {
              allowFramedSoftphone: true
            },
            storageAccess: {
              canRequest: false
            },
            pageOptions:{
              enablePhoneTypeSettings: true
            }
          });*/
        }
        this.appService.phoneState.next(true);
          
         /* connect.contact(contact => {
            // receive contact metadata
            const contactAttributes = contact?.getAttributes();
            console.log("Contact Attributes");
            console.log(contactAttributes);
  
            contact.onIncoming(function(contact) {
            });
    
            contact.onRefresh(function(contact) {
            });
    
            contact.onAccepted(function(contact) {
            });
    
            contact.onEnded(function() {
            });
    
            contact.onConnected(function() {
                    // console.log(`onConnected(${contact.getContactId()})`);
                    // var attributeMap = contact.getAttributes();
                    // var name = JSON.stringify(attributeMap["firstName"]["value"]);
                    // var phone = JSON.stringify(attributeMap["phoneNumber"]["value"]);
                    // var account = JSON.stringify(attributeMap["accountNumber"]["value"]);
                    // console.log(name);
                    // console.log(phone);
                    // console.log(account);
                    // window.alert("Customer's name: " + name + "\nCustomer's phone #: " + phone + "\nCustomer's account #: " + account);
    
            });
          });
  
          connect.agent(function(agent){
            const endpoint = connect.Endpoint.byPhoneNumber("+1" + phone.number);
            let states = agent.getAgentStates();
            let availState = states.filter(state => state.name === "Available")[0];
            agent.setState(availState, {
              success: function(){
                console.log("State Change Success");
              },
              failure: function(){
                console.log("State Change Failure");
              }
            });
            agent.connect(endpoint, {
              success: function(){
                console.log("Call Success");
              },
              failure: function(){
                console.log("Call Failure");
              }
            });
          });*/
        }
         //this.communication.showCallDialog(phone.number);
    
    
    selectTextMessage(person:Person, phone:Phone){
      if(!this.usesPhoneApi()){
        this.snackbar.open("Texting is not configured for your Community.")
        return;
      }
      
      if(this.person instanceof Prospect){
        this.personService.selectedProspect.next(this.person);
        let d = this.dialog.open(ChatDialogComponent,
          { data: {contact:person, phone:phone, itemType: "Prospect"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

        // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Not sure how to check if text sent here, since the modal closing doens't return anything. Reload data instead.
            if(this.getProspectData != null) {
                this.getProspectData();

                // Added this so the activities can be refreshed and marked as having their action complete if a text/email was sent.
                if(this.person instanceof Prospect){
                  this.personService.selectedProspect.next(this.person);
                }
            }
        });
      } else if(this.person instanceof Referrer){
        this.personService.selectedReferrer.next(this.person);
        let d = this.dialog.open(ChatDialogComponent,
          { data: {contact:person, phone:phone, itemType: "Referrer"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

        // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Not sure how to check if text sent here, since the modal closing doens't return anything. Reload data instead.
            if(this.getReferrerData != null) {
                this.getReferrerData();
            }
        });
      } else if (this.person instanceof ReferrerStaff) {
        this.personService.selectedReferrerStaff.next(this.person);
        let d = this.dialog.open(ChatDialogComponent,
          { data: {contact:person, phone:phone, itemType: "Staff"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

        // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Not sure how to check if text sent here, since the modal closing doens't return anything. Reload data instead.
            if(this.getReferrerStaffData != null) {
                this.getReferrerStaffData();
            }
        });
      }
    }

    sendEmail(person:Person, email:Email){
      if(this.person instanceof Prospect){
        this.personService.selectedProspect.next(this.person);
        this.communication.selectedEmail.next({contact:person, email:email});

        let d = this.dialog.open(EmailSendComponent,
          { data: {selectedEmail: email.address, itemType: "Prospect"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

        // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Check if email successfully sent.
            if(result != null && result == "Success") {
                if(this.getProspectData != null) {
                    this.getProspectData();

                    // Added this so the activities can be refreshed and marked as having their action complete if a text/email was sent.
                    if(this.person instanceof Prospect){
                      this.personService.selectedProspect.next(this.person);
                    }
                }
            }
        });
      } else if(this.person instanceof Referrer){
        this.personService.selectedReferrer.next(this.person);
        this.communication.selectedEmail.next({contact:person, email:email});

        var personObj = {personId: person.personId, name: person.firstname + " " + person.lastname + " <" + email.address + ">", email: email.address};
        
        let d = this.dialog.open(EmailSendComponent,
          { data: {selectedEmail: email.address, personObj: personObj, itemType: "Referrer"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

          // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Check if email successfully sent.
            if(result != null && result == "Success") {
                if(this.getReferrerData != null) {
                    this.getReferrerData();
                }
            }
        });
      } else if (this.person instanceof ReferrerStaff) {
        this.personService.selectedReferrerStaff.next(this.person);
        this.communication.selectedEmail.next({contact:person, email:email});

        var personObj = {personId: person.personId, name: person.firstname + " " + person.lastname + " <" + email.address + ">", email: email.address};
        
        let d = this.dialog.open(EmailSendComponent,
        { data: {selectedEmail: email.address, personObj: personObj, itemType: "Staff"},
            disableClose: true, 
                 width:"calc(100vw - 40px)",
                 maxWidth:"900px"},
                 );

        // On dialog close, update the First/Last Contact columns by grabbing the data.
        d.afterClosed().subscribe(result=>{
            // Check if email successfully sent.
            if(result != null && result == "Success") {
                if(this.getReferrerStaffData != null) {
                    this.getReferrerStaffData();
                }
            }
        });

      }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes["person"] && changes["person"].currentValue != changes["person"].previousValue 
        || (changes["other_people"] && changes["other_people"].currentValue != changes["other_people"].previousValue)) {
          this.phones = [];
          this.mobiles = [];
          this.emails = [];
          
          if(this.person != null){
            let header = Person.getRoleDisplay(this.person);
            let phones = this.person.phoneNumbers.filter(p=>p.is_primary && p.allowSalesContactCalls); // KE: 08/20/2024: Bug 47989: Separate functionality for allowing texting vs calling.
            if(phones.length > 0){
              this.phones.push({person:this.person, phones:phones, header:header});
            }
            
            let mobiles = this.person.phoneNumbers.filter(p=>p.contact_type=='M' && p.allowSalesContact);
            if(mobiles.length > 0){
              this.mobiles.push({person:this.person, phones:mobiles, header:header});
            }

            let emails = this.person.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
            if(emails.length > 0){
              this.emails.push({person:this.person, emails:emails, header:header});
            }
          }
          
          let firstPhone = true, firstMobile = true, firstEmail = true;
          if(this.other_people != null && this.other_people.length > 0){
            //sort other_people by type
            this.other_people.sort((a, b) => Person.getRoleDisplay(a).localeCompare(Person.getRoleDisplay((b))));
            let firstType = this.other_people[0].type;
            for(let other of this.other_people){
              //if other is type Person
              if (other instanceof Person) {
                if(other != null){
                  if (firstType != other.type) {
                    firstPhone = true;
                    firstMobile = true;
                    firstEmail = true;
                    firstType = other.type;
                  }
                  let header = Person.getRoleDisplay(other) + "(s)";
                  let phones = other.phoneNumbers.filter(p=>p.is_primary && p.allowSalesContactCalls); // KE: 08/20/2024: Bug 47989: Separate functionality for allowing texting vs calling.
                  if(phones.length > 0){
                    this.phones.push({person:other, phones:phones, header:firstPhone ? header : undefined});
                    firstPhone = false;
                  }
                  
                  let mobiles = other.phoneNumbers.filter(p=>p.contact_type=='M' && p.allowSalesContact);
                  if(mobiles.length > 0){
                    this.mobiles.push({person:other, phones:mobiles, header:firstMobile ? header : undefined});
                    firstMobile = false;
                  }

                  let emails = other.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails.length > 0){
                    this.emails.push({person:other, emails:emails, header:firstEmail ? header : undefined});
                    firstEmail = false;
                  }
                }
              }else if (other instanceof Organization){
                if(other != null){
                  if (firstType != other.type) {
                    firstPhone = true;
                    firstMobile = true;
                    firstEmail = true;
                    firstType = other.type;
                  }
                  let header = "Organization";
                  let phones = other.phoneNumbers.filter(p=>p.is_primary && p.allowSalesContactCalls); // KE: 08/20/2024: Bug 47989: Separate functionality for allowing texting vs calling.
                  if(phones.length > 0){
                    this.phones.push({person:other,phones:phones, header:firstPhone ? header : undefined});
                    firstPhone = false;
                  }
                  
                  let mobiles = other.phoneNumbers.filter(p=>p.contact_type=='M' && p.allowSalesContact);
                  if(mobiles.length > 0){
                    this.mobiles.push({person:other, phones:mobiles, header:firstMobile ? header : undefined});
                    firstMobile = false;
                  }

                  let emails = other.emailAddresses.filter(p=>p.is_primary && p.allowSalesContact);
                  if(emails.length > 0){
                    this.emails.push({person:other, emails:emails, header:firstEmail ? header : undefined});
                    firstEmail = false;
                  }
                }
              }
            }
          }
        }
    }
}
