import { Component, ElementRef, ViewChild } from '@angular/core';
import { faAnglesDown, faAnglesLeft, faArrowRightLong, faPhone } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService } from '../core/services/api.service';
import { PersonService } from '../core/services/person.service';
import { debounceTime, filter, first, map, mergeMap } from 'rxjs';
import { Community, User } from '../core/models/user.models';
import { AppService } from '../core/services/app.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardBannerCfg, DashboardService } from '../core/services/dashboard.service';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-view-wrapper',
  templateUrl: './sidebar-wrapper.html',
  styleUrls: ['../app.component.scss'],
})
export class SidebarWrapperComponent {
  buttonIcon = faAnglesDown;
  sectionIcon = faArrowRightLong;
  phoneIcon = faPhone;
  selectedColumn:""|"expand-main"|"expand-sub" = "";
  currentCommunity: Community | null = null;
  now = new Date();
  
  dashWidgetsVisible:boolean = true;
  
  currentDash:DashboardBannerCfg = DashboardService.NoDashboard;

  @ViewChild('ccpDiv') public ccpDiv!: ElementRef;
  @ViewChild("phone") phone!: ElementRef;
  
  constructor(public app:AppService, dashboard:DashboardService, personService:PersonService, router:Router, activatedRoute:ActivatedRoute){
    app.selectWidget.pipe(untilDestroyed(this), debounceTime(100)).subscribe(widget => {
      this.selectedColumn = "";
      if(widget){
        let element = widget.widgetCard.nativeElement;
        let parent = element.closest('.mainContent');
        if(parent) {
          this.selectedColumn = "expand-main";
        } else {
          parent = element.closest('.subContent');
          if(parent){
            this.selectedColumn = "expand-sub";
          }
        }
      }
    })
    
    app.sectionViewState.pipe(untilDestroyed(this), debounceTime(100)).subscribe(state => {
      Array.from(document.getElementsByClassName("mainContent")).forEach(el =>{
        el.scroll({top:0, left:0, behavior:'smooth'});
      });
      Array.from(document.getElementsByClassName("subContent")).forEach(el =>{
        el.scroll({top:0, left:0, behavior:'smooth'});
      });
      Array.from(document.getElementsByClassName("pageView")).forEach(el =>{
        el.scroll({top:0, left:0, behavior:'smooth'});
      });
    })

    personService.currentCommunity.pipe(untilDestroyed(this)).subscribe(community=>{
      if (community != null){
        this.currentCommunity = community;
        //this.initPhone();
      }
    });
    
    //Apparently it is not very nice to get route data in angular angular.
    //Pulled from here: 
    //https://stackoverflow.com/questions/49308304/get-custom-route-data-from-lazy-loaded-route-in-top-level-component/49308911#49308911
    router.events.pipe(untilDestroyed(this))
    .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => activatedRoute),
        map((route) => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }),
        mergeMap((route) => route.data))
    .pipe(debounceTime(100))
    .subscribe((x) => {
      // console.log(x);
      if(x['dashboard']){
        let cfg = x['dashboard'] as DashboardBannerCfg;
        if(this.currentDash.view != cfg.view){
          dashboard.cfg.next(cfg);
        }
      } else if(this.currentDash.view != DashboardService.NoDashboard.view) {
        dashboard.cfg.next(DashboardService.NoDashboard);
      }
    });
    
    dashboard.cfg.pipe(untilDestroyed(this)).subscribe(cfg=>{
      this.currentDash = cfg;
      switch(this.currentDash.view){
        case 'prospects':
        case 'occupancy':
        case 'referrer':
          this.dashWidgetsVisible = true;
          break;
        case 'none':
        default:
          this.dashWidgetsVisible = false;
          break;
      }
    })
  }
    
  ngAfterViewInit(){
    this.app.ccpDiv = this.ccpDiv.nativeElement;
  }

  toggleContainer(){
    this.app.dashWidgetsState.next(!this.app.dashWidgetsExpanded);
  }
    
  toggleSection(){
    this.app.sectionViewState.next(this.app.sectionView == "left" ? "right" : "left");
  }

  initPhone(){
    this.app.phoneState.pipe(untilDestroyed(this)).subscribe(state => {
      if (state) {
        this.phone.nativeElement.style.display = "none";
      } else {
        this.phone.nativeElement.style.display = "none";
      }
    });
  }

    
}
