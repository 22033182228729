import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, faMinusCircle, faMinus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { PlacementHistory, RcrmInvoiceRequest, User } from '../../models/user.models';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';
import { FormService } from '../../services/form.service';
import { PersonService } from '../../services/person.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataMapper } from '../../models/datamapper';
import { CompanyCfgService } from '../../services/company-cfg.service';

@UntilDestroy()
@Component({
  selector: 'create-rcrm-invoice-document',
  templateUrl: './create-rcrm-invoice-document.dialog.html',
  styleUrls: ['../../../form-styles.scss', './create-rcrm-invoice-document.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRcrmInvoiceDocumentDialog  {

  loadingDialogData = false;
  
  rcrmInvoiceRequest : RcrmInvoiceRequest;

  placement: PlacementHistory | undefined;
  currentUser: User | undefined;

  submitting: boolean = false;
  
  hasInvalidCheckedSelection = false;

  invoiceFormGroup!: FormGroup;

  hasValidItems = false;
  hasMultipleItems = false;

  // deleteIcon = faXmarkCircle;
  deleteIcon = faMinus;

  constructor(
      protected app: AppService,
      protected route: ActivatedRoute,
      protected router: Router,
      private personService: PersonService,
      private api: ApiService,
      protected snackbar: MatSnackBar,
      private formBuilder: FormBuilder,
      public lists: FormService,
      protected dialog: MatDialog,
      public dialogRef: MatDialogRef<CreateRcrmInvoiceDocumentDialog>, 
      private crd:ChangeDetectorRef,
      private companyCfg:CompanyCfgService, 
      private cdRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  
    this.placement = this.data;

    this.personService.currentUser.pipe(untilDestroyed(this)).subscribe(async user => { 
      if(user){
        this.currentUser = user;
      }
    });

    let obj = {placement_id: this.placement?.placementId, description_list: [], amount_list: []};

    this.rcrmInvoiceRequest = new RcrmInvoiceRequest(obj);
  }

  ngOnInit() {
    this.invoiceFormGroup = this.buildInvoiceGroup();
    setTimeout(() => {
      this.addItem();
    });
  }

  buildInvoiceGroup() {
    return this.formBuilder.group({
      invoiceNumber: ["", [Validators.required]],
      lineItems: this.formBuilder.array([]),
      invoicePercentage: ["", [Validators.required, Validators.max(100)]]// Zach Hansen, 12/03/20204, bug 48230: added percentage value
    });
  }

  buildLineGroup() {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      amount:["", [Validators.required]],
    })
  }

  get lineItemsList() {
    return this.invoiceFormGroup.get('lineItems') as FormArray;
  }

  addItem() {
    this.lineItemsList.push(this.buildLineGroup());
    if (this.lineItemsList.length > 1) {
      this.hasMultipleItems = true;
    } else {
      this.hasMultipleItems = false;
    }
    this.invoiceFormGroup.updateValueAndValidity();
    //keeps everything looking nice, even when debuging this function
    setTimeout(() => {
      this.cdRef.detectChanges();
    });
  }
  
  removeItem(index:number) {
    this.lineItemsList.removeAt(index);
    if (this.lineItemsList.length > 1) {
      this.hasMultipleItems = true;
    } else {
      this.hasMultipleItems = false;
    }
    this.invoiceFormGroup.updateValueAndValidity();
    //keeps everything looking nice, even when debuging this function
    setTimeout(() => {
      this.cdRef.detectChanges();
    });
  }

  async createInvoiceDocument(){
    this.rcrmInvoiceRequest.invoice_number = this.invoiceFormGroup.controls['invoiceNumber']?.value;

    // Zach Hansen, 12/03/20204, bug 48230: now include percentage value.
    this.rcrmInvoiceRequest.invoice_percentage = this.invoiceFormGroup.controls['invoicePercentage']?.value;

    let lineItems:FormGroup[] = this.lineItemsList.controls as FormGroup[];
    for (const control of lineItems) {
      this.rcrmInvoiceRequest.description_list.push(control.controls['description'].value);
      this.rcrmInvoiceRequest.amount_list.push(control.controls['amount'].value);
    }
    let resp = await this.api.post('placement-history/create-invoice', this.rcrmInvoiceRequest);

    if(resp && resp.message){
      this.snackbar.open(resp.message);
    }else{
      this.snackbar.open("Generating and Uploading Invoice");
      this.dialogRef.close();
    }
   }
}


