
<span>
    <span>
        <button mat-stroked-button *ngIf="phones.length > 0" [matMenuTriggerFor]="phoneMenu"><fa-icon [icon]="phoneIcon"></fa-icon></button>
        <mat-menu #phoneMenu="matMenu">
            <div *ngFor="let contact of phones">
                <div *ngIf="contact.header" class="subText menuHeader">
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer'">Contact</span>
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer(s)'">Contact(s)</span>
                    <span *ngIf="!(referrerCRM() && (contact.header == 'Referrer' || contact.header == 'Referrer(s)'))">{{contact.header}}</span>
                </div>
                <ng-container *ngIf="isPerson(contact)">
                    <mat-label class="oneline">{{contact.person.prettyName()}}</mat-label>
                    <ng-container *ngFor="let phone of contact.phones">
                    
                        @if(usesPhoneApi()){
                            <button mat-menu-item (click)="selectCall(contact.person, phone)">
                                <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                                {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                            </button>
                        }@else {
                            <button mat-menu-item>
                                <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                                <a href="tel:+1{{phone.number}}" style="color:inherit;text-decoration:none">{{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}</a>
                            </button>
                        }
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isOrganization(contact)">
                    <mat-label class="oneline">{{contact.person.nameDisplay}}</mat-label>
                    <ng-container *ngFor="let phone of contact.phones">
                        @if(usesPhoneApi()){
                            <button mat-menu-item (click)="selectCall(contact.person, phone)">
                                <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                                {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                            </button>
                        }@else {
                            <button mat-menu-item>
                                <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                                <a href="tel:+1{{phone.number}}" style="color:inherit;text-decoration:none">{{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}</a>
                            </button>
                        }
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
            </div>
        </mat-menu>
    </span>
    <span>
        <button mat-stroked-button *ngIf="mobiles.length > 0" [matMenuTriggerFor]="messageMenu"><fa-icon [icon]="messageIcon"></fa-icon></button>
        <mat-menu #messageMenu="matMenu">
            <div *ngFor="let contact of mobiles">
                <div *ngIf="contact.header" class="subText menuHeader">
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer'">Contact</span>
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer(s)'">Contact(s)</span>
                    <span *ngIf="!(referrerCRM() && (contact.header == 'Referrer' || contact.header == 'Referrer(s)'))">{{contact.header}}</span>
                </div>
                <ng-container *ngIf="isPerson(contact)">
                    <mat-label class="oneline">{{contact.person.prettyName()}}</mat-label>
                    <ng-container *ngFor="let phone of contact.phones">
                        <button mat-menu-item (click)="selectTextMessage(contact.person, phone)">
                            <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                            {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isOrganization(contact)">
                    <mat-label class="oneline">{{contact.person.nameDisplay}}</mat-label>
                    <ng-container *ngFor="let phone of contact.phones">
                        <button mat-menu-item (click)="selectTextMessage(contact.person, phone)">
                            <!-- <fa-icon *ngIf="phone.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                            {{phone.number | mask:"(000) 000-0000" : { prefix: "" } }} {{phone.extension != null && phone.extension.length > 0 ? "Ext:" + phone.extension : ""}}
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
            </div>
        </mat-menu>
    </span>
    <span>
        <button mat-stroked-button *ngIf="emails.length > 0" [matMenuTriggerFor]="emailMenu"><fa-icon [icon]="emailIcon"></fa-icon></button>
        <mat-menu #emailMenu="matMenu">
            <div *ngFor="let contact of emails">
                <div *ngIf="contact.header" class="subText menuHeader">
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer'">Contact</span>
                    <span *ngIf="referrerCRM() && contact.header == 'Referrer(s)'">Contact(s)</span>
                    <span *ngIf="!(referrerCRM() && (contact.header == 'Referrer' || contact.header == 'Referrer(s)'))">{{contact.header}}</span>
                </div>
                <ng-container *ngIf="isPerson(contact)">
                    <mat-label class="oneline">{{contact.person.prettyName()}}</mat-label>
                    <ng-container *ngFor="let email of contact.emails">
                        <button class="oneline" mat-menu-item (click)="sendEmail(contact.person, email)">
                            <!-- <fa-icon *ngIf="email.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                            {{email.address}}
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isOrganization(contact)">
                    <mat-label class="oneline">{{contact.person.nameDisplay}}</mat-label>
                    <ng-container *ngFor="let email of contact.emails">
                        <button class="oneline" mat-menu-item (click)="sendEmail(contact.person.nameDisplay, email)">
                            <!-- <fa-icon *ngIf="email.is_primary" [icon]="starIcon" class="primary-icon"></fa-icon> -->
                            {{email.address}}
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                </ng-container>
            </div>
        </mat-menu>
    </span>
</span>